import { combineReducers } from 'redux';

import routing from './routing';
import controllers from './controllers';
import i18n from './i18n';
import common from './common';
import home from './home';
import donateInfo from './donate/info';
import donateForm from './donate/form';
import donateReceipt from './donate/receipt';
import donateWelcome from './donate/welcome';
import educationProgram from './educationProgram';
import advocacyProgram from './advocacyProgram';
import campaign from './campaign';
import event from './event';
import video from './video';
import blog from './blog/blogType';
import blogDetail from './blog/blogDetail';
import project from './project';
import impactEvent from './impact/event';
import vision from './vision';
import mission from './mission';
import culture from './culture';
import methodology from './methodology';
import team from './team';
import mentor from './mentor';
import career from './career';
import impactReport from './impactReport';
import brandBook from './brandBook';
import donationInfo from './donation/info';

const reducers = combineReducers({
	...routing.reducer,
	...controllers.reducer,
	...i18n.reducer,
	...common.reducer,
	...home.reducer,
	...donateInfo.reducer,
	...donateForm.reducer,
	...donateReceipt.reducer,
	...donateWelcome.reducer,
	...educationProgram.reducer,
	...advocacyProgram.reducer,
	...campaign.reducer,
	...event.reducer,
	...video.reducer,
	...blog.reducer,
	...blogDetail.reducer,
	...project.reducer,
	...impactEvent.reducer,
	...impactReport.reducer,
	...vision.reducer,
	...mission.reducer,
	...culture.reducer,
	...methodology.reducer,
	...team.reducer,
	...mentor.reducer,
	...career.reducer,
	...brandBook.reducer,
	...donationInfo.reducer,
});

export default reducers;
